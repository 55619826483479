<template>
  <!-- 产品与服务页面 -->
  <div class="product-page">
    <div class="banner">
      <img :src="$IMG_BASE_URL+pageData.bannerData.bannerImg" alt="" @load="bannerLoadOver = true">
      <div class="banner-text fc-white" :class="bannerLoadOver? 'animate': ''">
        <p class="fs-24 subtitle">{{ pageData.bannerData.subTitle }}</p>
        <p class="title fw-bold">{{ pageData.bannerData.title }}</p>
        <p class="fs-18 text line-break">{{ pageData.bannerData.bannerText }}</p>
      </div>
    </div>
    <!-- 页面锚点 -->
    <div class="nav" v-if="caseIsFinshed">    
      <a-anchor @click="handleAnchorClick" :target-offset="targetOffset" :offsetTop="offsetTop">
        <a-anchor-link v-for="(item, index) in anchorData" :key="index" :href="item.href" :title="item.title" />
        <div class="service-btn fc-white bg-blue fs-18" @click="isShowCommitP = true">
          <a-icon type="message" class="mr-10" />
          <span>服务咨询</span>
        </div>
      </a-anchor>
    </div>
    <!-- 内容展示部分 -->
    <div class="content-wrap">
      <!-- 适用场景 -->
      <div class="scene-part" id="sceneId">
        <div class="w">
          <h1 class="num-title t-center"><i>01</i><span>适用场景</span></h1>
          <div class="content">
            <img v-show="pageData.sceneData.sceneImg" :src="$IMG_BASE_URL+pageData.sceneData.sceneImg" alt="">
            <ul class="scene-text" v-if="!!pageData.sceneData.tagArr">
              <li class="fs-20 fc-content" v-for="(item, index) in pageData.sceneData.tagArr" :key="'tag'+index">
                <span class="bg-blue fc-white"><img class="icon" :src="item.icon" alt="">{{ item.name }}</span>
                <p>{{ item.text }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 行业痛点 -->
      <div class="pain-part" id="painId">
        <div class="w">
          <h1 class="num-title t-center"><i>02</i><span>核心痛点</span></h1>
          <div class="content">
              <div class="item" :class="{'item-reverse': item.type === 'bottom'}" v-for="(item, index) in pageData.painList" :key="'pain'+index">
                <div class="pain-img"><img :src="$IMG_BASE_URL+item.painImg" alt=""></div>
                <div class="pain-text" :style="{backgroundImage: `url(${$IMG_BASE_URL+item.painBg})`}">
                  <p class="fs-22 fc-title mb-15 fw-bold">{{ item.title }}</p>
                  <p class="fs-18 fc-content">{{ item.painText }}</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- 产品功能 1-->
      <div class="use-part" :class="(pageData.useList.length === 1 ? 'use-part-2': 'use-part-1')" id="useId">
        <div class="w">
          <h1 class="num-title t-center"><i>03</i><span>产品功能</span></h1>
          <div class="swiper-box" v-if="(pageData.useList.length>=3)">
             <!-- 产品功能 - swiper -->
             <swiper :options="swiperOptions" ref="mySwiper">
              <swiper-slide class="item-wrap" v-for="(item, index) in pageData.useList" :key="'use'+ index">
                <div class="item bg-white">
                  <div class="use-img"><img :src="$IMG_BASE_URL+item.useImg" alt=""></div>
                  <div class="use-text t-center">
                    <p class="fs-22 fc-title fw-bold">{{ item.title }}</p>
                    <p class="fs-18 fc-content">{{ item.useText }}</p>
                  </div>
                </div>
              </swiper-slide>
             </swiper>
            <!-- 产品功能 - navigation -->
             <div v-show="pageData.useList.length > 3" class="use-pre-btn pre-btn arrow-btn"><a-icon type="left" /></div>
             <div v-show="pageData.useList.length > 3" class="use-next-btn next-btn arrow-btn"><a-icon type="right" /></div>
          </div>
          <div class="img-box" v-if="(pageData.useList.length === 1)">
            <img style="width:1200px;" :src="($IMG_BASE_URL + pageData.useList[0].useImg)"/>
          </div>
        </div>
      </div>
      <!-- 核心优势 -->
      <div class="merit-part" id="meritId">
        <div class="w">
          <h1 class="num-title t-center"><i>04</i><span>核心优势</span></h1>
          <div class="content">
            <div class="item-wrap">
              <div class="item" :style="{backgroundImage: `url(${$IMG_BASE_URL+item.meritImg})`}" v-for="(item, index) in pageData.meritList" :key="'merit'+index">   
                <p class="fs-22 fc-title fw-bold">{{ item.title }}</p>
                <hr>
                <p class="fs-18 fc-content">{{ item.meritText }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 优质案例 -->
      <div class="case-part" id="caseId" v-if="caseIsFinshed && (pageData.caseList.length>0)">
       <div class="w">
        <h1 class="num-title t-center"><i>05</i><span>优质案例</span></h1>
        <div class="swiper-box" v-if="pageData.caseList.length>0">
          <!-- 优质案例 - swiper -->
          <swiper :options="caseSwiperOptions" ref="mySwiper">
            <swiper-slide class="item-wrap" v-for="(item, index) in pageData.caseList" :key="'case'+ index">
              <!-- 图文混合case -->
              <div v-if="item.caseType === 'text'" class="item case-mixin-item bg-white">
                <div class="case-img"><img :src="$IMG_BASE_URL+item.caseImg" alt=""></div>
                <div class="case-text">
                  <p class="fs-26 fc-title mb-20 fw-bold">{{ item.title }}</p>
                  <p class="fs-16 fc-content line-break">{{ item.caseText }}</p>
                  <p class="rate-p">
                    <span><i class="fc-title fw-bold">{{ item.tag1Val || '' }}</i> <img :src="$IMG_BASE_URL+item.tag1Icon" alt=""> <br> <i class="fc-content">{{ item.tag1Name }}</i></span>
                    <span><i class="fc-title fw-bold">{{ item.tag2Val || '' }}</i> <img :src="$IMG_BASE_URL+item.tag2Icon" alt=""> <br> <i class="fc-content">{{ item.tag2Name }}</i></span>
                    <span><i class="fc-title fw-bold">{{ item.tag3Val || '' }}</i> <img :src="$IMG_BASE_URL+item.tag3Icon" alt=""> <br> <i class="fc-content">{{ item.tag3Name }}</i></span>
                  </p>
                </div>
              </div>
              <!-- 图片类型case -->
              <div class="item case-img-item" v-else><img :src="$IMG_BASE_URL+item.caseImg" alt="" /></div>
            </swiper-slide>
            </swiper>
            <!-- 优质案例 - navigation -->
            <div v-show="pageData.caseList.length > 1" class="case-pre-btn pre-btn arrow-btn"><a-icon type="left" /></div>
            <div v-show="pageData.caseList.length > 1" class="case-next-btn next-btn arrow-btn"><a-icon type="right" /></div>
        </div>
       </div>
      </div>
    </div>
    <!-- 留言弹窗 -->
    <message-form class="common-message-form" v-model="isShowCommitP" :mask="true" @close="isShowCommitP = false"></message-form>
  </div>
</template>

<script>
import MessageForm from '@/components/MessageForm'
import { Anchor, Carousel, Icon } from 'ant-design-vue'
import { swiper,swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import api from '@/api'
export default {
  components: {
    [Anchor.name]: Anchor,
    [Anchor.Link.name]: Anchor.Link,
    [Carousel.name]:Carousel,
    [Icon.name]: Icon,
    swiper,
    swiperSlide,
    MessageForm
  },
  data() {[]
    return {
      isShowCommitP: false, // 是否显示留言弹窗
      channelIds: [ // 页面所有栏目的id
        { channelId: null, dataKey: 'bannerData', type: 'Obj'},
        { channelId: null, dataKey: 'sceneData', type: 'Obj' },
        { channelId: null, dataKey: 'painList', type: 'Arr' },
        { channelId: null, dataKey: 'useList', type: 'Arr' },
        { channelId: null, dataKey: 'meritList', type: 'Arr' },
        { channelId: null, dataKey: 'caseList', type: 'Arr' },
      ],
      targetOffset: null,
      offsetTop: 0,
      anchorData: [ // 锚点数据
        { title: '适用场景', href: '#sceneId' },
        { title: '核心痛点', href: '#painId' },
        { title: '产品功能', href: '#useId' },
        { title: '核心优势', href: '#meritId' },
        { title: '优质案例', href: '#caseId' },
      ],
      swiperOptions: { // 产品功能swiper配置项
        slidesPerView : 3, // 设置一页展示三个元素
        slidesPerGroup : 1,
        loop: false,
        navigation: { // 左右切换
          nextEl: '.use-next-btn',
          prevEl: '.use-pre-btn',
        },
      },
      caseSwiperOptions: { // 优质案例swiper配置项
        slidesPerView : 1,
        loop: false,
        navigation: {
          nextEl: '.case-next-btn',
          prevEl: '.case-pre-btn',
        },
      },
      pageData: {}, // 页面数据
      bannerLoadOver: false, // 图片是否加载完成
      caseIsFinshed: false, 
    }
  },  
  created() {
    // 初始化pageData中的值
    this.channelIds.map(item => this.$set(this.pageData, item.dataKey ,item.type === 'Obj' ? {} : []) )

    // 获取携带的父级栏目id，没有携带id，阻止后续操作
    const pChannelId = this.$route.query.id 
    if(!pChannelId) return
    this.getPageData(pChannelId)

  },  
  mounted() {
    this.targetOffset = window.innerHeight / 3;
  },
  methods: {
    // 获取子栏目id
    async getPageData(pChannelId) {
      const { children = [] } = await api.getChannel({ channelId: pChannelId })
      children.map((item, index) => {
        this.channelIds[index].channelId = item.id 
      })

      // 通过id，获取对应栏目下的文章数据
      this.channelIds.forEach(item => item.channelId && this.getModuleData(item))
    },
    // 调接口 - 获取栏目数据
    async getModuleData({ channelId, dataKey, type }) {
      const { message, contents } = await api.getContentsList({ channelId })

      if(type === 'Obj') { // 区分type类型，进行赋值，只过滤审核通过的数据
        const index = contents.findIndex(item => item.state === '已审核')
        this.pageData[dataKey] = contents[index] || {}
      } else {
        this.pageData[dataKey] = contents.filter(item => item.state === '已审核') || []
      }
      
      if(dataKey === 'useList') this.swiperOptions.loop = this.pageData[dataKey].length > 3
      if(dataKey === 'caseList') {
        // 超过1个就做loop
        this.caseSwiperOptions.loop = this.pageData[dataKey].length > 1 

        // 如果没有配置案例，需要删除锚点数据
        if(this.pageData[dataKey].length === 0) {
          this.anchorData.splice(4,1)
        }
        this.caseIsFinshed = true
      }

      dataKey === 'sceneData' && this.formatScene(this.pageData[dataKey]) 
    },
    // 格式化场景数据
    formatScene(obj) {
      const keyArr = ['tag1', 'tag2', 'tag3']
      this.pageData.sceneData.tagArr = []
      keyArr.map((key, index) => {
        const icon = obj[key+'Icon'] && this.$IMG_BASE_URL+obj[key+'Icon'] || '' // 本地开发需加上imgBaseUrl展示图片
        const name = obj[key+'Name'] || ''
        const text = obj[key+'Text'] || ''

        // 配置了任一内容，都需要展示
        if(icon||name||text) this.pageData.sceneData.tagArr.push({ icon, name, text }) 

        console.log('====', this.pageData.sceneData.tagArr)
      })
    },
    // 点击锚点标签
    handleAnchorClick(e,link) {
      e.preventDefault();
      var srcolls = document.getElementById(link.href.split('#')[1]);
      srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
    }
  }
}
</script>

<style lang="less" scoped>
  @import url('~@/styles/m.less');
  @import url('~@/styles/animate.less');
  @import url(./index.less);
</style>